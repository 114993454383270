
/******************************************************/
/*       Methodenwrapper für Bootbox-Dialoge          */
/******************************************************/

/* gemeinsamer Code für Radio-/Checkboxen */
function _dlrg_choice(inputType, titel, beschreibung, choices, defaults, onclick, size) {
    let options = [];
    for (let i = 0; i < choices.length; i++) {
        options.push({text: choices[i], value: choices[i]});
    }

    bootbox.prompt({
        title: titel,
        inputType: inputType,
        value: defaults,
        inputOptions: options,
        onEscape: true,
        centerVertical: true,
        size: size,
        message: beschreibung + "<br/>&nbsp;",
        buttons: {
            confirm: {
                label: '<i class="fas fa-check"></i> Ok',
                className: 'btn-primary',
            },
            cancel: {
                label: '<i class="fas fa-times"></i> Schließen',
                className: 'btn-warning',
            }
        },
        callback: onclick
    });
}

/* Öffnet einen modalen Dialog mit einem Textfeld. Eingabe ist erforderlich.
    Input:
    - titel: Titelzeile
    - beschreibung: textuelle Beschreibung zwischen Titelzeile und Eingabe
    - defaultText: Vorbelegung des Eingabefeldes
    - onclick: Handler mit einem formalen Parameter (Text) bei "ok"
    - size (optional): "sm" (default), "md", "lg"
    Output:
    - Text bei 'ok' oder null bei 'abbrechen'
    Beispiel:
    <button type="button" class="btn btn-success btn-sm ml-1" onclick="dlrg_TextField('Mein Titel',
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore',
        '12345',
        function(res) {
            dlrg_MessageBox('Nachricht', 'Wichtige Information :'+ res);
        })">
        TextField-Knopf
    </button>
*/
function dlrg_TextField(titel, beschreibung, defaultText, onclick, size="sm") {
    bootbox.prompt({
        title: titel,
        value: defaultText,
        onEscape: true,
        centerVertical: true,
        size: size,
        message: beschreibung,
        buttons: {
            confirm: {
                label: '<i class="fas fa-check"></i> Ok',
                className: 'btn-primary',
            },
            cancel: {
                label: '<i class="fas fa-times"></i> Schließen',
                className: 'btn-warning',
            }
        },
        callback: onclick
    });
}

/* Öffnet einen modalen Dialog mit einer Textarea. Eingabe ist erforderlich.
    Input:
    - titel: Titelzeile
    - beschreibung: textuelle Beschreibung zwischen Titelzeile und Eingabe
    - defaultText: Vorbelegung des Eingabefeldes
    - onclick: Handler mit einem formalen Parameter (Text) bei "ok"
    - size (optional): "sm", "md" (default), "lg"
    Output:
    - Text bei 'ok' oder null bei 'abbrechen'
    Beispiel:
    <button type="button" class="btn btn-success btn-sm ml-1" onclick="dlrg_TextArea('Mein Titel',
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore',
        '12345',
        function(res) {
            dlrg_MessageBox('Nachricht', 'Wichtige Information :'+ res);
        })">
        TextArea-Knopf
    </button>
*/
function dlrg_TextArea(titel, beschreibung, defaultText, onclick, size="md") {
    bootbox.prompt({
        title: titel,
        inputType: 'textarea',
        value: defaultText,
        onEscape: true,
        centerVertical: true,
        size: size,
        message: beschreibung,
        buttons: {
            confirm: {
                label: '<i class="fas fa-check"></i> Ok',
                className: 'btn-primary',
            },
            cancel: {
                label: '<i class="fas fa-times"></i> Schließen',
                className: 'btn-warning',
            }
        },
        callback: onclick
    });
}

/* Öffnet einen modalen Dialog mit einer Radioknopfleiste. Auswahl ist erforderlich.
    Input:
    - titel: Titelzeile
    - beschreibung: textuelle Beschreibung zwischen Titelzeile und Eingabe
    - choices: Array von Radioknopftexten
    - defaultVal: Radioknopftext, der vorselektiert ist
    - onclick: Handler mit einem formalen Parameter (Array mit einem Auswahltext) bei "ok"
    - size (optional): "sm", "md" (default), "lg"
    Output:
    - Auswahltext bei 'ok' oder null bei 'abbrechen'
    Beispiel:
    <button type="button" class="btn btn-success btn-sm ml-1" onclick="dlrg_SingleChoice('4er-Einfachauswahl',
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore',
        ['a', 'b', 'c', 'd'],
        '',
        function(res) {
            dlrg_MessageBox('Nachricht', 'Wichtige Information :'+ res);
        })">
        Einfachauswahl
    </button>
*/
function dlrg_SingleChoice(titel, beschreibung, choices, defaultVal, onclick, size="md") {
    _dlrg_choice("radio", titel, beschreibung, choices, defaultVal, onclick, size);
}

/* Öffnet einen modalen Dialog mit einer Liste von Checkboxen. Auswahl ist erforderlich.
    Input:
    - titel: Titelzeile
    - beschreibung: textuelle Beschreibung zwischen Titelzeile und Eingabe
    - choices: Array von Checkboxtexten
    - defaults: Array von Checkboxtexten, die vorselektiert sind
    - onclick: Handler mit einem formalen Parameter (Array mit Auswahltexten) bei "ok"
    - size (optional): "sm", "md" (default), "lg"
    Output:
    - Auswahltextliste (Array) bei 'ok' oder null bei 'abbrechen'
    Beispiel:
    <button type="button" class="btn btn-success btn-sm ml-1" onclick="dlrg_MultiChoice('5er-Mehrfachauswahl',
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        ['a', 'b', 'c', 'd', 'e'],
        ['a', 'd'],
        function(res) {
            dlrg_MessageBox('Nachricht', 'Wichtige Information :'+ res);
        })">
        Mehrfachauswahl
    </button>
*/
function dlrg_MultiChoice(titel, beschreibung, choices, defaults, onclick, size="sm") {
    _dlrg_choice("checkbox", titel, beschreibung, choices, defaults, onclick, size);
}

/* Öffnet einen modalen Dialog mit einer Selection-Liste (Dropdown list). Auswahl ist erforderlich.
    Input:
    - titel: Titelzeile
    - beschreibung: textuelle Beschreibung zwischen Titelzeile und Eingabe
    - choices: Array von Select-Optionen
    - defaultVal: Optionstext, der vorselektiert ist
    - onclick: Handler mit einem formalen Parameter (Array mit einem Auswahltext) bei "ok"
    - size (optional): "sm", "md" (default), "lg"
    Output:
    - Auswahltext bei 'ok' oder null bei 'abbrechen'
    Beispiel:
    <button type="button" class="btn btn-success btn-sm ml-1" onclick="dlrg_Select('10er-Einfachauswahl', 'lg',
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore',
        ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j','k'], 'e', function(res){dlrg_MessageBox('Nachricht', 'sm', 'Wichtige Information :'+ res);})">
        große  Einfachauswahl
    </button>
*/
function dlrg_Select(titel, beschreibung, choices, defaultVal, onclick, size="md") {
    _dlrg_choice("select", titel, beschreibung, choices, defaultVal, onclick, size);
}

/* Öffnet einen modalen Confirm-Dialog.
    Input:
    - titel: Titelzeile
    - text: Beschreibungstext
    - onConfirm: Handler bei "ja" bzw. "löschen"; keine formalen Parameter
    - loeschen (=false; optional): Wenn ja, wird Dialog als Löschbestätigungsdialog geöffnet
    - size (optional): "sm" (default), "md", "lg"
    - onReject (optional): Handler bei "nein" bzw. "abbrechen"; keine formalen Parameter
    Beispiel:
    <button type="button" class="btn btn-success btn-sm ml-1"
        onclick="dlrg_Confirm('Eine Auswahl', 'Möchtest Du diesen Teppich kaufen?',
            function() {dlrg_MessageBox('Nachricht', 'Ja, ich möchte diesen Teppich kaufen')}, false,
            function() {dlrg_MessageBox('Nachricht', 'Ich möchte diesen Teppich nicht kaufen.');})">
        Teppich-Knopf
    </button>
*/
function dlrg_Confirm(titel, text, onConfirm, loeschen = false, size="sm", onReject) {
    bootbox.confirm({
        title: titel,
        centerVertical: true,
        size: size,
        message: text,
        buttons: {
            confirm: {
                label: loeschen ? '<i class="fas fa-trash-alt"></i> Löschen' : '<i class="fas fa-check"></i> Ja',
                className: loeschen ? 'btn-danger' : 'btn-primary',
            },
            cancel: {
                label: loeschen ? '<i class="fas fa-times"></i> Abbrechen' : '<i class="fas fa-times"></i> Nein',
                className: 'btn-warning',
            }
        },
        callback: function (result) {
            if (result) {
                onConfirm()
            } else if (onReject) {
                onReject()
            }
        }
    });
}

/* Öffnet einen modalen Message-Dialog mit "Ok"-Knopf.
    Input:
    - titel: Titelzeile
    - text: Beschreibungstext
    - size (optional): "sm" (default), "md", "lg"
    Beispiel:
    <button type="button" class="btn btn-success btn-sm ml-1"
        onclick="dlrg_MessageBox('Fehlermeldung' 'Ich möchte diesen Teppich nicht kaufen.');">
        Teppich-Knopf
    </button>
*/
function dlrg_MessageBox(titel, text, size="sm"){
    bootbox.alert({
        title: titel,
        centerVertical: true,
        size: size,
        message: text
    });
}
